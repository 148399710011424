import axios from 'axios';
import router from '@/router';

export default {
  state: {
    password: {
      type: "text",
      name: "password",
      value: null,
      error: null,
      status: "default",
    },
    confirm: {
      type: "text",
      name: "confirm",
      value: null,
      error: null,
      status: "default",
    },
    validation: false,
  },
  getters: {
    getSetPassword: state => state.password,
    getSetConfirm: state => state.confirm,
    getSetValidation: state => state.validation,
  },
  mutations: {
    handleSetChanges(state, payload) {
      if (payload.event === 'change' || payload.event === 'paste' || payload.event === 'input') {
        let module = {
          state: state,
          payload: payload,
        }

        this.commit("handleValidation", module);
      }
    },
  },
  actions: {
    sendSetChanges(context) {
      context.commit("showPreloader");

      axios
        .post("https://api.airwet.app/api/mobile/users/sharedAccess/setPassword", {
          data: {
            identifier: localStorage.getItem('invite__phone-value'),
            password: context.state.password.value,
            passwordConfirmation: context.state.confirm.value
          }
        })
        .then((response) => {
          context.commit("hidePreloader");

          if (response.status === 200) {
            router.push("/login");

            let payload = {
              type: "set",
            };

            this.commit("createPopup", payload);
          }
        })
        .catch((error) => context.commit("handleError", error));
    }
  },
}